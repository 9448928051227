export function state() {
  return {
    list: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, printers) => (state.list = printers),
}

export const actions = {

  async fetchItem(_, id) {
    const data = await this.$axios.$get(`/printers/${id}`, {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    })
    return Promise.resolve(data.printer)
  },
  async fetchItems({ commit }) {
    const data = await this.$axios.$get('/printers', {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    })

    commit('SET_ITEMS', data.printers)

    return Promise.resolve(data.printers)
  },
  async create({ dispatch }, form) {
    const data = await this.$axios.$post('/printers', form, {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    })

    this.$notify({
      type: 'success',
      title: 'Success',
      text: 'Printer Created',
    })

    dispatch('fetchItems')

    return Promise.resolve(data.printer)
  },

  async update({ dispatch }, form) {
    const data = await this.$axios.$put(`/printers/${form.id}`, form, {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    })

    this.$notify({
      type: 'success',
      title: 'Success',
      text: 'Printer Updated',
    })

    dispatch('fetchItems')

    return Promise.resolve(data.printer)
  },

  async remove({ dispatch }, id) {
    console.log(id)

    const data = await this.$axios.$delete(`/printers/${id}`, {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    })

    dispatch('fetchItems')

    this.$notify({
      type: 'success',
      title: 'Success',
      text: 'Printer Removed',
    })

    return Promise.resolve(data)
  },
}

export const getters = {
  getPrinters: state => state.list,
}
