export default function ({ route, store, redirect }) {
  const publicRoutes = ['/login', '/']
  const isLoggedIn = store.state.auth.loggedIn
  const { token } = route.query

  if (token && route.path === '/login') {
    return
  }

  if (!isLoggedIn && publicRoutes.includes(route.path)) {
    return
  }

  if (isLoggedIn && publicRoutes.includes(route.path) && route.path !== '/location-select') {
    return redirect('/location-select')
  }
}
