import Middleware from '../../middleware'

const options = JSON.parse(`{"spa":"history","siteId":"PEDAXTUG","url":"https://cdn.usefathom.com/script.js","honorDNT":false,"includedDomains":["https://greenlinedcs.com/","localhost:3000"],"excludedDomains":["google.com"],"canonical":true,"namespace":"fathom"}`)
const { namespace } = options

Middleware[namespace] = ({ app, route }) => {
  if(process.client) {
    try {
      app.$fathom?.trackPageview({
        url: route.fullPath
      })
    } catch (error) {
      // possibility that an adblocker is being used...
      console.log(error)
    }
  }
}
