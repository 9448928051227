import * as fathom from 'fathom-client'

const options = JSON.parse(`{"spa":"history","siteId":"PEDAXTUG","url":"https://cdn.usefathom.com/script.js","honorDNT":false,"includedDomains":["https://greenlinedcs.com/","localhost:3000"],"excludedDomains":["google.com"],"canonical":true,"namespace":"fathom"}`)
const { siteId, spa, includedDomains, excludedDomains, canonical, honorDNT, url } = options

export default (context , inject) => {
  fathom.load(siteId, {
    spa,
    includedDomains,
    excludedDomains,
    canonical,
    honorDNT,
    url
  })

  inject('fathom', fathom)
}
