<script>
import Logo from '~/assets/images/logo.svg'

// eslint-disable-next-line import/no-webpack-loader-syntax
import NotFoundSVG from '!!vue-svg-loader!../assets/images/404.svg'
// eslint-disable-next-line import/no-webpack-loader-syntax
import ServerErrorSVG from '!!vue-svg-loader!../assets/images/500.svg'

export default {
  components: { NotFoundSVG, ServerErrorSVG },
  layout: 'blank',
  props: {
    error: { type: Object, default: null },
  },
  data: () => ({
    backgroundImage: `url(${require('~/assets/images/background.svg')})`,
    Logo,
  }),
  computed: {
    homePage() {
      return this.$store.getters.isManager ? '/' : '/pos/new'
    },
    url() {
      if (localStorage.getItem('company-id') !== 'undefined')
        return `/location-select`
      else
        return `/location-select`
    },
  },
}
</script>

<template>
  <div
    :style="{ backgroundImage }"
    class="bg-white bg-bottom bg-center bg-no-repeat bg-cover"
  >
    <div class="flex items-center justify-center min-h-screen">
      <div class="absolute top-0 left-0 pt-10 pl-10 select-none">
        <nuxt-link :to="url">
          <img alt="" :src="Logo">
        </nuxt-link>
      </div>
      <div class="absolute top-0 right-0 mr-1 text-center select-none">
        <h3
          class="pt-6 pr-8 text-2xl leading-none tracking-wide uppercase font-mohave text-turquoise-dark"
        >
          White Bear <br>Cleaners
        </h3>
      </div>
      <!-- Main Content Goes Here -->
      <div class="flex flex-col flex-wrap items-center justify-center">
        <div
          v-if="error.statusCode === 404"
          class="flex flex-col flex-wrap items-center justify-center"
        >
          <h1 class="mb-5 text-2xl">
            Oh no! The page you are looking for does not exist.
          </h1>
          <NotFoundSVG height="250" />
        </div>

        <div
          v-else-if="error.statusCode === 500"
          class="flex flex-col flex-wrap items-center justify-center"
        >
          <h1 class="mb-5 text-2xl">
            Whoops! Seems like the server is having some issues.
          </h1>
          <ServerErrorSVG height="250" />
          <nuxt-link
            :to="homePage"
            tag="button"
            class="h-10 px-4 mt-5 text-base font-semibold bg-white border-2 rounded-full border-turquoise text-turquoise"
          >
            Home page
          </nuxt-link>
        </div>
        <div v-else-if="error.statusCode === 403">
          {{ error.message }}
          <div class="mt-5 text-center">
            <a
              :href="error.response.data.message"
              class="h-20 p-3 mt-5 text-base font-semibold bg-white border-2 rounded-full border-turquoise text-turquoise"
            >Go to Payment Page</a>
          </div>
        </div>
        <div v-else-if="error.statusCode === 401">
          {{ error.message }}
          <div class="mt-5 text-center">
            <a
              href="/"
              class="h-20 p-3 mt-5 text-base font-semibold bg-white border-2 rounded-full border-turquoise text-turquoise"
            >Back To Dashboard</a>
          </div>
        </div>
        <div v-else>
          {{ error.message }}
          <a href="javascript:void(0)" @click="$auth.logout()">Logout</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error__page {
  background-position-y: 12em;
}
</style>
