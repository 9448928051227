var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between w-full h-16 text-xs bg-white"},[_c('div',{staticClass:"flex items-center"},[_c('HeaderCustomerInfo',{staticClass:"justify-start h-full"})],1),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[_c('AppButton',{staticClass:"mx-2 text-xs font-semibold leading-tight lg:mx-4 lg:w-auto md:w-40",nativeOn:{"click":function($event){return _vm.newOrder.apply(null, arguments)}}},[_c('Icon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(" New Order\n    ")],1),_vm._v(" "),_c('div',{staticClass:"font-medium"},[_vm._v("\n      "+_vm._s(_vm.currentTime)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"relative"},[_c('button',{staticClass:"flex items-center justify-center py-2 pr-10 text-sm font-semibold text-left text-white bg-transparent bg-gray-200 rounded-lg focus:outline-none",on:{"click":function($event){_vm.openSort = !_vm.openSort}}},[_c('Avatar',{staticClass:"block h-8 mx-4 rounded-full shadow-md",attrs:{"src":_vm.user.avatar}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.openSort),expression:"openSort"}],staticClass:"absolute right-0 w-48 mt-2 origin-top-right z-100"},[_c('div',{staticClass:"px-2 pt-2 pb-2 bg-white rounded-md shadow-lg dark-mode:bg-gray-700"},[_c('div',{staticClass:"flex flex-col"},[_c('nuxt-link',{staticClass:"flex flex-row items-start p-2 bg-transparent rounded-lg hover:turquoise hover:bg-gray-200",attrs:{"to":{
                  name: 'employees-index-id',
                  params: { id: _vm.user.id },
                }}},[_c('p',{},[_vm._v("\n                  Edit Profile\n                ")])]),_vm._v(" "),(_vm.domain !== 'demo-company')?_c('a',{staticClass:"flex flex-row items-start p-2 bg-transparent rounded-lg hover:turquoise hover:bg-gray-200",attrs:{"href":"#"},on:{"click":_vm.openSubscriptionManagement}},[_vm._m(0)]):_vm._e(),_vm._v(" "),_c('nuxt-link',{staticClass:"flex flex-row items-start p-2 bg-transparent rounded-lg hover:turquoise hover:bg-gray-200",attrs:{"to":{
                  name: 'change-password',
                }}},[_c('div',{},[_c('p',{},[_vm._v("\n                    Change Password\n                  ")])])]),_vm._v(" "),_c('a',{staticClass:"flex flex-row items-start p-2 bg-transparent rounded-lg hover:bg-gray-200",attrs:{"href":"#"},on:{"click":_vm.logout}},[_vm._m(1)])],1)])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('p',{},[_vm._v("Manage Subscription")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('p',{},[_vm._v("Logout")])])
}]

export { render, staticRenderFns }