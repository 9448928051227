export function state() {
  return {
    items: [],
  }
}

export const mutations = {
  setItems(state, data) {
    state.items = data
  },
}

export const actions = {
  async fetchItems({ commit }, params) {
    try {
      return await this.$axios
        .$get(`/reports`, {
          params,
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        })
        .then((data) => {
          data.data.totalPages = data.meta.total
          commit('setItems', data.data)
          return Promise.resolve(state.items)
        })
    }
    catch {}
  },
}
