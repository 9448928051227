import { flare } from '@flareapp/js'
import Vue from 'vue'

export default () => {
  // eslint-disable-next-line node/prefer-global/process
  if (process.client) {
    // eslint-disable-next-line node/prefer-global/process
    flare.light(process.env.FLARE_API_KEY)

    // eslint-disable-next-line node/prefer-global/process
    if (process.env.NODE_ENV !== 'production') {
      flare.test()
    }

    Vue.prototype.$flare = flare

    Vue.config.errorHandler = (error, vm, info) => {
      flare.report(error, {
        component: vm.$options.name,
        info,
      })
    }
  }
}
