import moment from 'moment'

export const actions = {
  async printReceipt({ rootGetters }, { ticketId, numberOfCopies }) {
    try {
      const headers = {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
          'x-location': rootGetters.currentLocation.slug,
        },
      }

      const params = {
        ticketId,
        locationId: rootGetters.currentLocation.id,
        currentTime: moment().format('MMM D h:mmA'),
        numberOfCopies,
      }

      const data = await this.$axios.$post(`/printReceipt`, { params }, headers)

      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Print job created',
      })

      return data?.msg
    }
    catch (error) {
      console.log(error)
      return error
    }
  },

  async printReceipts({ dispatch }, selectedTickets) {
    console.log('printReceipts', selectedTickets)
    // go through all tickets
    const promises = selectedTickets.map(({ id }) => {
      // print the ticket
      return dispatch('printReceipt', { ticketId: id, numberOfCopies: 2 })
    })

    // sends the requests concurrently
    await Promise.all(promises)
  },
}
